@use '@angular/material' as mat;
@use 'font-lato.css';

// Arabic fonts
@font-face {
  font-family: "ALFATEMI1";
  src: local("AL-FATEMI"), url(./assets/fonts/Fatimi2.ttf) format("truetype");
}

@include mat.core();

// Define your custom palettes
$fmb-primary: mat.m2-define-palette(mat.$m2-teal-palette, 800, 200, 900);
$fmb-accent: mat.m2-define-palette(mat.$m2-gray-palette, 700, 100, 900);
$fmb-warn: mat.m2-define-palette(mat.$m2-red-palette, 700, 400, 900);

$fmb-typography: mat.m2-define-typography-config($font-family: 'Lato');

@include mat.typography-hierarchy($fmb-typography);

// Create your Angular Material theme
$fmb-theme: mat.m2-define-light-theme((color: (primary: $fmb-primary,
        accent: $fmb-accent,
        warn: $fmb-warn ),
      typography: $fmb-typography));

@include mat.core-theme($fmb-theme);
@include mat.all-component-themes($fmb-theme);

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

body {
  height: 100%;
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
}

.content {
  flex: 1 0 auto;
  height: 100%;
}

.footer {
  flex-shrink: 0;
  text-align: center;
  font-size: 11px;
  color: grey;
  padding: 5px;
  padding-bottom: env(safe-area-inset-bottom); // iphone X bottom margin
}

a {
  color: mat.m2-get-color-from-palette($fmb-primary, darker);
  text-decoration: none;
}

.inset-spacer-bottom {
  // iphone X and above hack.
  // prevents scrolling content to not show in top inset area.
  position: fixed;
  z-index: 20;
  bottom: 0;
  height: env(safe-area-inset-bottom); // whole bar is invisible if 0 height in normal browser
  width: 100%;
  background-color: white;
  opacity: 1;
}

// ------------------STYLE EXTENSIONS------------------
.mdc-dialog__actions {
  padding: 0.5rem 1.5rem 1rem 1.5rem !important;
}

.mat-mdc-tab {
  max-width: 160px;
}

mat-chip-list {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 1rem;
}



.mat-datepicker-content {
  box-shadow: 0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f;
  background-color: #fff;
  color: #000000de
}

.mat-calendar-body-selected {
  background-color:  mat.m2-get-color-from-palette($fmb-primary);
  color: #fff;
}

.mat-calendar-body-cell-content {
  border: none !important;
}