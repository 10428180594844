@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 100;
  src: url(assets/fonts/Lato/Lato-italic-100.woff2) format('woff2'),
       url(assets/fonts/Lato/Lato-italic-100.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 300;
  src: url(assets/fonts/Lato/Lato-italic-300.woff2) format('woff2'),
       url(assets/fonts/Lato/Lato-italic-300.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 400;
  src: url(assets/fonts/Lato/Lato-italic-400.woff2) format('woff2'),
       url(assets/fonts/Lato/Lato-italic-400.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 700;
  src: url(assets/fonts/Lato/Lato-italic-700.woff2) format('woff2'),
       url(assets/fonts/Lato/Lato-italic-700.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: italic;
  font-weight: 900;
  src: url(assets/fonts/Lato/Lato-italic-900.woff2) format('woff2'),
       url(assets/fonts/Lato/Lato-italic-900.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 100;
  src: url(assets/fonts/Lato/Lato-normal-100.woff2) format('woff2'),
       url(assets/fonts/Lato/Lato-normal-100.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 300;
  src: url(assets/fonts/Lato/Lato-normal-300.woff2) format('woff2'),
       url(assets/fonts/Lato/Lato-normal-300.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  src: url(assets/fonts/Lato/Lato-normal-400.woff2) format('woff2'),
       url(assets/fonts/Lato/Lato-normal-400.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  src: url(assets/fonts/Lato/Lato-normal-700.woff2) format('woff2'),
       url(assets/fonts/Lato/Lato-normal-700.woff) format('woff');
}

@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: url(assets/fonts/Lato/Lato-normal-900.woff2) format('woff2'),
       url(assets/fonts/Lato/Lato-normal-900.woff) format('woff');
}
